.spec > span {
    color: var(--black);
    font-weight: bold;
    line-height: 1.2;
    padding-inline: 5px;
}

.spec > span:first-child {
    padding-left: 0;
}

.spec > span:last-child {
    padding-right: 0;
}

.spec {
    align-items: center;
    display: block;
}

[data-is-small] .spec {
    font-size: 12px;
}

.one-line {
    max-width: 640px;
    overflow: hidden;
    height: 22rem;
}

.teal span {
    color: var(--teal);
    font-weight: 300;
}