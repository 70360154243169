.icon {
    --size: 18rem;

    font-size: var(--size);
    position: absolute;
}

.off {
    pointer-events: none;
}

.icon:not(.off) {
    cursor: pointer;
}