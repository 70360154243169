.title {
    color: var(--black);
    font-size: 15rem;
    margin-bottom: 30px;
}

.inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title,
.inner {
    text-align: center;
}

.inner a {
    font-size: 12rem;
}

.inner b {
    color: #999;
}

@media screen and (min-width: 1051px) {
    .title,
    .inner {
        text-align: left;
    }
}