.alert {
    --green: #d4edda;
	--green-b: #c3e6cb;
    --orange: #fff3cd;
    --orange-b: #ffe69c;
    --orange-c: #664d03;
    --orange-l: #664d03;

    align-items: center;
    background: var(--orange);
    border: 1px solid var(--orange-b);
    border-radius: 16px;
    color: var(--orange-c);
    display: flex;
    margin-bottom: 1rem;
    padding: 12px 16px;
    position: relative;
}

.alert span:last-child {
    align-self: flex-start;
    margin-left: auto;
}

.alert span i {
    cursor: pointer;
    margin-right: 0;
}