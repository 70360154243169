.cf-image,
.cf-image-contain {
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
}

.cf-image-contain {
    height: 100%;
    object-fit: contain;
}