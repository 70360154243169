.form-links {
    appearance: none;
    display: flex;
    margin-bottom: 16px;
    max-width: 100%;
    overflow-x: auto;
}

.form-links::-webkit-scrollbar {
    appearance: none;
    height: 0;
}

.form-link {
    align-items: center;
    display: flex;
    height: 38px;
    justify-content: center;
    min-width: 90px;
    max-width: 120px;
    padding-inline: var(--padding);
}

.form-link.active {
    --radius: calc(var(--border-radius) / 2);

    background: var(--teal);
    border-radius: var(--radius);
    color: #fff;
}

.dropdown-inner {
    --pad: 20px;

    background: var(--white);
    border-radius: 0;
    box-shadow: 0 0 var(--shadow-radius) var(--box-shadows);
    display: none;
    height: 100vh;
    height: 100dvh;
    left: 0;
    max-height: 100vh;
    max-height: 100dvh;
    max-width: 100vw;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: var(--pad);
    padding-bottom: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;
}

.dropdown-inner.open {
    display: flex;
    flex-direction: column;
    pointer-events: all;
    z-index: 50;
}

.dropdown-inner h4 {
    font-weight: normal;
}

.bold * {
    font-weight: bold;
}

@media screen and (min-width: 1051px) {
    .dropdown-inner {
        border-radius: var(--border-radius);
        height: fit-content;
        max-height: 460px;
        position: absolute;
        top: 86%;
    }

    .dropdown-inner.open {
        z-index: 10;
    }

    .form-links {
        margin-bottom: 0;
    }

    .form-link.active {
        border-radius: var(--radius) var(--radius) 0 0;
    }
}