.head {
    border-bottom: 1px solid var(--input-border-c);
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 -20px;
    max-width: calc(100% + 40px);
    padding: 0 20px 16px 20px;
    width: 100vw;
}

.inner {
    display: flex;
}

.close {
    cursor: pointer;
    margin-left: auto;
}

.actions,
.actions>div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    text-wrap: nowrap;
    white-space: nowrap;
}

.actions>div:first-child {
    flex-wrap: nowrap;
    overscroll-behavior: contain;
    overflow-x: auto;
}

.actions>div::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.actions {
    margin-top: 16px;
}

.btn,
.abtn {
    border: 1px solid;
    cursor: pointer;
    padding: 6px 8px;
    user-select: none;
}

.btn {
    opacity: .8;
}

.abtn {
    background: var(--teal);
    border-color: var(--teal);
    color: white;
}