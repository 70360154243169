.bar {
    background: var(--subtle-gray-alt);
}

.bar-inner {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 0;
}

.bar-inner,
.breadcrumbs,
.back,
.content {
    align-items: center;
    display: flex;
}

.breadcrumbs {
    gap: 12px;
}

.content {
    gap: 36px;
}


.back {
    --size: 36px;

    background: var(--white);
    border-radius: 50%;
    cursor: pointer;
    height: var(--size);
    justify-content: center;
    width: var(--size);
    user-select: none;
}

.back i {
    font-size: 14rem;
    padding: 0;
}