.wrap {
    position: relative;
    width: 100%;
}

.flex {
    animation: fadeIn .3s forwards ease-out;
    background: var(--white);
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 50;
}

.close {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.label {
    color: var(--black);
    display: block;
    font-weight: bold;
}

.input {
    background: var(--gray);
    border: 1px solid var(--box-shadows) !important;
    border-radius: 8px !important;
    color: var(--teal);
    font-size: 15rem;
    font-weight: bold;
    height: 36px;
    margin-bottom: 20px;
    margin-top: 4px;
    padding-inline: 8px !important;
    width: 100%;
}

.input::placeholder {
    color: var(--black);
}

.hints {
    --h: 55px;

    flex-grow: 1;
    font-size: 16rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-bottom: var(--h);
}

.hints p {
    cursor: pointer;
    margin-block: 22px;
}

.hints>p {
    align-items: center;
    border-top: 1px solid var(--box-shadows);
    cursor: pointer;
    display: flex;
    font-size: 16rem;
    height: var(--h);
    margin: 0;
    padding-inline: 20px;
    position: relative;
}

.hints>p::after {
    content: 'chevron_right';
    font-family: 'Material Icons';
    position: absolute;
    right: 20px;
}

.ip {
    padding-inline: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}