.clear-icon,
.loading-icon,
.toggle-icon {
    bottom: calc(var(--bpad, 20px) + var(--car-search-input-h) / 2 - (var(--size) / 2));
}

.clear-icon {
    right: calc(var(--rpad, 20px) + var(--size) + 6rem);
}

.loading-icon,
.toggle-icon {
    right: var(--rpad, 20px);
}

i.loading-icon {
    animation: rotate .8s infinite ease-in-out;
    color: var(--teal);
}

.disabled input {
    background: var(--gray);
    pointer-events: none;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}