.tier {
    --pad: 20px;

    background: var(--dark-blue);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: var(--pad);
}

.tier *:not(button) {
    color: #fff;
}

.title {
    margin: 0;
}

.tip {
    font-size: 26rem !important;
    margin-top: -12px !important;
}

.divider {
    border-bottom: 3px solid #fff;
    margin-block: 30px;
    margin-inline: calc(var(--pad) * -1);
    width: calc(100% + 2 * var(--pad));
}

.price {
    font-size: 56rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.subtitle {
    color: var(--teal);
    font-weight: bold;
}

.feature {
    align-items: center;
    display: flex;
}

.button {
    color: var(--black);
    font-size: 16rem;
    font-weight: bold;
    margin: auto;
    margin-bottom: 12px;
}

.disabled {
    opacity: .2;
    pointer-events: none;
}

.tier.teal {
    background: var(--teal);
}

.teal .subtitle {
    color: var(--dark-blue);
}

.feature i {
    font-size: 18px;
    padding: 0;
}

.tier a {
    cursor: pointer;
    opacity: .8;
}