.unread,
.read {
    cursor: pointer;
    margin: 12px 20px 0 0;
}

.unread .topic {
    font-weight: bold;
}

.unread .inner:after {
    content: 'new_releases';
    display: inline-block;
    font-family: 'Material Icons';
    margin: -4px 0 0 5px;
    vertical-align: middle;
}

.read {
    opacity: .9;
}

.time {
    color: var(--teal);
    opacity: .9;
}

.topic {
    align-items: center;
    display: flex;
    position: relative;
}

.note {
    font-size: 11rem;
    opacity: .4;
}

.topic i {
    font-size: 20px;
}
