.sticky {
    display: flex;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.sticky > a,
.sticky > button {
    flex: 0 0 50%;
    user-select: none;
}