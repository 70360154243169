.grid {
    display: grid;
    font-weight: bold;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-block: 20px;
}

.for-list {
    display: flex;
    gap: 20px;
    margin-block: 0;
    max-width: 80%;
}

.grid > span {
    align-items: center;
    color: var(--black);
    display: flex;
    gap: 6px;
}

.grid > span > img {
    max-width: 15px;
}