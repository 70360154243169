.drawer {
    --space: 10px;

    border-bottom: 1px solid var(--box-shadows);
    margin-bottom: var(--space);
}

.drawer label {
    align-items: center;
    color: var(--black);
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding-bottom: var(--space);
}

.content {
    margin-top: var(--space);
}

.closed {
    display: none;
}

.open {
    display: block;
}

.last {
    border: none;
    margin-bottom: 20px;
}