.slider {
    --gap: 20px;
    /* --gap: var(--n-container-hz-pad); */

    align-items: flex-start;
    display: flex;
    gap: var(--gap);
    margin-inline: 0px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-align: start;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
}

.slider:empty {
    display: none;
}

.slider::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.slider::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
}

.slider::-webkit-scrollbar-track {
    background: transparent;
}

.slide {
    --w: calc(100vw - 2 * var(--gap));
    --q: calc(var(--w) * 0.125);
    --width: calc(var(--w) - var(--q));

    align-self: stretch;
    min-width: var(--width);
    max-width: var(--width);
    scroll-snap-align: start;
    width: var(--width);
}

.slide:not(.small) article h3 {
    font-size: 26rem;
}

.control {
    --size: 38px;

    align-items: center;
    background: var(--white);
    border-radius: 50%;
    color: var(--black);
    cursor: pointer;
    display: flex;
    height: var(--size);
    justify-content: center;
    position: absolute;
    top: calc(50% - var(--size) * 0.5);
    user-select: none;
    width: var(--size);
}

.control.left {
    left: -40px;
}

.control.right {
    right: -40px;
}

@media screen and (min-width: 641px) {
    .slide {
        --w: calc((100vw - (3 * var(--gap))) * 0.5);
        --h: calc(var(--w) * 0.5);
        --width: calc(var(--w) + var(--h));
    }
}

@media screen and (min-width: 1051px) {
    .slider {
        padding-inline: 20px;
    }

    .slide {
        --tw: calc(100vw - 40px - 3 * var(--gap));
        --w: calc(var(--tw) * 0.333);
        --h: calc(var(--w) * 0.25);
        --width: calc(var(--w) + var(--h));
    }
}

@media screen and (min-width: 1461px) {
    .slider {
        --gap: 40px;
    }

    .slider.small {
        --gap: 20px;
    }

    .slide {
        --width: calc((var(--n-container-wdt) - 2 * var(--gap)) / 3);
    }

    .slide.small {
        --width: calc((var(--n-container-wdt) - 4 * var(--gap)) / 5);
    }
}