/* .article {
    font-size: 0.8em;
} */

.article figure {
    margin-left: 0;
    margin-right: 0;
}

.article img {
    max-width: 100%;
}

.article p {
    line-height: 1.6;
}

.article table {
    border-collapse: collapse;
}

.article td {
    border: solid 1px var(--input-border-c);
    padding: 0 10px;
    text-align: center;
}

/* @media screen and (min-width: 801px) {
    .article {
        font-size: 14rem;
    }
} */