.mobile,
.totop {
    background: var(--teal);
    border: 1px solid white;
    border-radius: 50%;
    bottom: 30px;
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    position: fixed;
    right: 30px;
    text-align: center;
    width: 60px;
    z-index: 1000;
}

.mobile {
    bottom: 86px;
}

.mobile i,
.totop i {
    color: white;
    margin-top: -5px;
    user-select: none;
}

.totop {
    animation: jump .6s normal forwards ease-in-out;
}

.mobile {
    animation: mobile .6s normal forwards ease-in-out;
}

.monile:hover i,
.totop:hover i {
    animation-name: pulse;
    animation-duration: .3s;
}

@keyframes jump {
    from {
        height: 57px;
        bottom: 30px
    }

    50% {
        height: 63px;
        bottom: 100px
    }

    to {
        height: 60px;
        bottom: 30px
    }
}

@keyframes mobile {
    from {
        height: 57px;
        bottom: 60px
    }

    50% {
        height: 63px;
        bottom: 130px
    }

    to {
        height: 60px;
        bottom: 86px;
    }
}

@keyframes pulse {
    50% {
        transform: scale(1.4)
    }
}